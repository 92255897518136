var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d01b0657a415f9ff94f1d5ddcc67870851d8e0ef"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://e3b9b0cbfce94dc0b921fd90eb175bb0@o157879.ingest.sentry.io/6275247',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    environment: process.env.NEXT_PUBLIC_ENV,

    /**
     * Refer to the docs for more info:
     * https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/understanding-sessions/
     * This sets the sample rate to be 0% which means that session recordings are
     * not started or sent off automatically
     */
    replaysSessionSampleRate: 0,
    /**
     * This sets the error based sample rate to be 100% which means that session
     * recordings are not sent off unless they throw an error.
     * If, additionally, there is a callback defined for beforeErrorSampling, as
     * part of the integrations section below, then not every error will trigger
     * a session replay transmission. The callback further defines which
     * recordings ultimately get sent (i.e. only certain errors).
     *
     * Enable the next line instead of the one below it whenever we investigate a
     * live bug that Sentry reports on (to get a video recording for such sessions)
     */
    // replaysOnErrorSampleRate: 1.0,
    replaysOnErrorSampleRate: 0,

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
        interactionsSampleRate: 0.5,
      }),
      Sentry.replayIntegration({
        // Prevent Sentry from capturing PII data on video
        maskAllText: true,
        // Allow images / icons etc to appear on video
        blockAllMedia: false,
        /**
         * Sets out which conditions will need to be met to send the session
         * recording off to the Sentry server (returning true means sending off
         * the recording if the replaysOnErrorSampleRate is set to 1). If it is
         * set to a value that satisfies [0 < replaysOnErrorSampleRate < 1], then
         * returning true means that a subset of all form sessions that throw this
         * error will be sending their recordings to Sentry's server.
         */
        beforeErrorSampling: (error) => {
          // Logging the error object out to discover what the structure of relevant errors is
          // since it does not seem to confine with what the SENTRY docs believe it to be.
          // This will help when debugging production bugs and trying to send session video
          // recordings for certain errors only.
          // eslint-disable-line no-console
          console.log(
            'MVF Info - the error: ',
            error,
            'and its message: ',
            error.message
          );
          return true;
          // const shouldThisErrorGetReportedWithVideo =
          //   error.message?.includes(
          //     "Cannot read properties of undefined (reading 'replace')"
          //   ) ||
          //   error.exception?.values[0]?.value?.includes(
          //     "Cannot read properties of undefined (reading 'replace')"
          //   );
          // return shouldThisErrorGetReportedWithVideo;
        },
      }),
    ],
  });
}
